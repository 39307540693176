<template>
    <div>
        <v-card class="rounded-lg" elevation="4">
            <v-tabs fixed-tabs background-color="#327d3c" dark>
                <v-tabs-slider color="#ceded0"></v-tabs-slider>
                <v-tab>EXAMES DE ENTRADA REGISTRADOS</v-tab>

                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                        <v-form @submit.prevent="exameDonorForm" ref="form">
                            <v-container>
                                <p style="font-weight:bold">Paciente Doador: {{ this.donor_name }}</p>
                            </v-container>
                        </v-form>
                    </v-card-text>
                </v-card>               
                    <v-data-table 
                        :headers="headers"
                        :items="entrace_exams"
                        :footer-props="{'items-per-page-text': 'Exames por Página',}"
                        :page="page"
                        :loading="loading">
                
                        <template v-slot:[`item.actions`]="{ item }" :resource="item">
                            <v-tooltip left color="blue">
                                <template v-slot:activator="{ on, attrs }">
                                <v-icon 
                                    size="24"
                                    class="icon"
                                    color="blue" 
                                    align-item="center"
                                    v-on="on"
                                    v-bind="attrs"
                                @click="$router.push({path: '/visualizar-doador', query:{item:item}})">mdi-pencil
                                </v-icon>
                                </template>
                                <span style="color:white">Editar Exames</span>
                            </v-tooltip>

                            <v-tooltip right color="#327d3c">
                                <template v-slot:activator="{ on, attrs }">
                                <v-icon 
                                    size="24"
                                    class="icon"
                                    color="#327d3c" 
                                    align-item="center"
                                    v-on="on"
                                    v-bind="attrs"
                                @click="$router.push({path: '/visualizar-doador', query:{item:item}})">mdi-eye 
                                </v-icon>
                                </template>
                                <span style="color:white">Visualizar Exames</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-tab-item>

                
            </v-tabs>
        </v-card>
        <v-snackbar absolute background-color="white" color="#40a374" v-model="snackbar">
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                Fechar
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
  
<script>
import axios from "@/axios"
export default {
    data() {
        return {
            headers: [
            {
              text: "Data de Entrada de Exame",
              value: "created_at",
              align: "center",
            },
            {
              text: "Responsável pelo cadastro",
              value: "users.name",
              align: "center",
            },
            {
              text: "Ações",
              value: "actions",
              align: "center",
            }
            ],
            dialog: false,
            activateButtons: false,
            displayError: {
                display: false,
                message: [],
            },
            loading: true,
            options: {},
            exameDonorForm: {},
            message: "",
            snackbar: false,
            messageError: "",
            donorData: "",
            donorID: "",
            donor_name: "",
            entrace_exams: [],
        };
    },

    created() {
        const donorData = { ...this.$route.query.item };
        this.donorID = donorData.id;
        this.donor_name = donorData.name;
            axios.get(`/entrace_exams/${this.donorID}`).then((response) => { 
                this.loading = false;
                this.entrace_exams = response.data;
            });
    },

    watch: {
        options: {
            handler() {
                this.indoDataTable();
            },
        },
        deep: true,
    },

    methods: {
        indoDataTable() {
            this.loading = true;
        },
    },

    mounted() {
        this.indoDataTable();
    },

};
</script>
  
<style scoped>

.deathDialog>h4{
    color: #413f3f;
    padding: 20px;
}
.text-h5{
    background-color: #50c78f;
    color: white;
    text-shadow: 1px 2px 2px #309171
}
.donorIcon{
    padding:  8px 8px 8px 0;
}
.header-table {
    display: flex;
    margin-bottom: 20px;
}

p{
    font-size: 16px;
    /* font-weight: bold; */
}
.d-flex {
    padding: 0 6px;
}

.space {
    width: 5px;
}

.space-personalized {
    padding: 4px;
}

.spacediferent {
    width: 7px;
}

.v-btn:not(.v-btn--round).v-size--default {
    height: 40px !important;
}

.textBtn {
    font-family: "Poppins";
    color: #ffffff;
}

.create-user-dialog {
    padding: 20px;
}

.card {
    padding: 35px;
}

.banner {
  background: #b3ffab;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right,
      #0ca673,
      #1abc9c);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right,
      #0cb087,
      #66f9b9);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.textBtn{
  color:#ffffff;
}
.banner>h3 {
  color: #ffffff;
  padding: 18px 0 18px 0;
}
.modalIcon{
  padding: 12px;
}

 .cardFooter {
    padding: 0 !important;
}
</style>
  