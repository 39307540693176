var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"rounded-lg",attrs:{"elevation":"4"}},[_c('v-tabs',{attrs:{"fixed-tabs":"","background-color":"#327d3c","dark":""}},[_c('v-tabs-slider',{attrs:{"color":"#ceded0"}}),_c('v-tab',[_vm._v("EXAMES DE ENTRADA REGISTRADOS")]),_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.exameDonorForm.apply(null, arguments)}}},[_c('v-container',[_c('p',{staticStyle:{"font-weight":"bold"}},[_vm._v("Paciente Doador: "+_vm._s(this.donor_name))])])],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.entrace_exams,"footer-props":{'items-per-page-text': 'Exames por Página',},"page":_vm.page,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":"","color":"blue"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icon",attrs:{"size":"24","color":"blue","align-item":"center"},on:{"click":function($event){return _vm.$router.push({path: '/visualizar-doador', query:{item:item}})}}},'v-icon',attrs,false),on),[_vm._v("mdi-pencil ")])]}}],null,true)},[_c('span',{staticStyle:{"color":"white"}},[_vm._v("Editar Exames")])]),_c('v-tooltip',{attrs:{"right":"","color":"#327d3c"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icon",attrs:{"size":"24","color":"#327d3c","align-item":"center"},on:{"click":function($event){return _vm.$router.push({path: '/visualizar-doador', query:{item:item}})}}},'v-icon',attrs,false),on),[_vm._v("mdi-eye ")])]}}],null,true)},[_c('span',{staticStyle:{"color":"white"}},[_vm._v("Visualizar Exames")])])]}}],null,true)})],1)],1)],1),_c('v-snackbar',{attrs:{"absolute":"","background-color":"white","color":"#40a374"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Fechar ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }